export const TUTORIALS_BY_PAGE_URL = [
  {
    pageUrl: '/dashboard',
    featureName: 'Dashboard',
    videoUrl: 'https://www.marketmoversapp.com/video-tutorials/market-movers-x-dashboard/',
  },
  {
    pageUrl: '/collections',
    featureName: 'My Collection',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-collection/',
  },
  {
    pageUrl: '/charts',
    featureName: 'Charts',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-charts/',
  },
  {
    pageUrl: '/stats/movements',
    featureName: 'Movements',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-movements/',
  },
  {
    pageUrl: '/market-pulse',
    featureName: 'Market Pulse',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-market-pulse/',
  },
  {
    pageUrl: '/deals',
    featureName: 'Deals',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-deals/',
  },
  {
    pageUrl: '/ratios',
    featureName: 'Intelligence Reports',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-intelligence-reports/',
  },
  {
    pageUrl: '/alerts',
    featureName: 'Alerts',
    videoUrl: 'https://marketmoversapp.com/video-tutorials/market-movers-x-alerts/',
  },
];
