import { useCallback, useEffect, useState } from 'react';
import { getItem, setItem, subscribe } from '../utils/localStorage';

/**
 * Hook to sync state with local storage
 */
export default function useLocalStorageValue<TValue extends any = string>(
  key: string,
  parse = false
): [TValue | null, (value: TValue) => void] {
  const [stateValue, setStateValue] = useState(() => getItem<TValue>(key, parse));
  useEffect(() => subscribe(key, (value) => setStateValue(value)), [key]);
  const setValue = useCallback((newValue: TValue) => setItem(key, newValue), [key]);
  return [stateValue, setValue];
}
