import { ReactNode, useCallback, useMemo } from 'react';
import { useQuery, useQueryClient, useMutation } from 'react-query';
import { useRouter } from 'next/router';
import { AccessTokenPayload } from '@sportscardinvestor/market-movers-api-client';
import * as sciAuth from '../../services/sciApi/authentication';
import * as mmAuth from '../../services/mmApiX/authentication';
import { mmApiClient } from '../../services/mmApiX/index';
import { authQueryKey } from '../../services/mmApiX/types';
import { trackFirebaseEvent } from '../../services/firebase/analytics';
import { parseJwt } from '../../utils/jwt';
import { AuthContextValue, LoginWithBasicAuthFn, FinishAuthFn, SetAuthStateFn } from './types';
import AuthContext from './AuthContext';
import useStableFunctionIdentity from '@/sci-ui-components/hooks/useStableFunctionIdentity';

interface AuthQueryData {
  isLoggedIn: boolean;
  isLoggingIn: boolean;
  mmAuthToken: string | null;
  sciAuthToken: string | null;
  authUser: AccessTokenPayload | null;
  impersonatedByUserId?: string;
}

const defaultAuthState: AuthQueryData = {
  isLoggedIn: false,
  isLoggingIn: true,
  mmAuthToken: null,
  sciAuthToken: null,
  authUser: null,
};

export default function AuthContextProvider({ children }: { children: ReactNode }) {
  const loginWithBasicAuthMutation = useMutation(mmApiClient.auth.loginWithBasicAuth.mutate);
  const loginWithSSOCodeMutation = useMutation(mmApiClient.auth.loginWithSSOCode.mutate);
  const {
    data = defaultAuthState,
    refetch,
    isLoading,
  } = useQuery<AuthQueryData>(
    authQueryKey,
    async () => {
      let sciAuthToken = sciAuth.getAuthTokenFromLocalStorage();
      let mmAuthToken = mmAuth.getAuthTokenFromLocalStorage();
      const refreshToken = mmAuth.getRefreshTokenFromLocalStorage();
      if (refreshToken && (!mmAuthToken || !sciAuthToken)) {
        // one of the access tokens expired
        try {
          const refreshed = await mmApiClient.auth.getFreshAccessToken.mutate({
            refreshToken,
          });
          sciAuth.setAuthTokenInLocalStorage(refreshed.legacySciApiAccessToken);
          mmAuth.setAuthTokenInLocalStorage(refreshed.accessToken);
          sciAuthToken = refreshed.legacySciApiAccessToken;
          mmAuthToken = refreshed.accessToken;
        } catch (err) {
          // something is wrong with refresh token
          mmAuth.clearRefreshTokenInLocalStorage();
        }
      }
      const authUser = mmAuthToken ? parseJwt<AccessTokenPayload>(mmAuthToken) : null;
      const isLoggedIn = !!sciAuthToken && !!sciAuthToken && !!authUser;
      const impersonatedByUserId = authUser?.impersonatedByUserId;

      return {
        isLoggedIn,
        isLoggingIn: false,
        mmAuthToken,
        sciAuthToken,
        authUser,
        impersonatedByUserId,
      };
    },
    {
      staleTime: 0,
      onSettled: (data, error) => {
        if (error || !data?.isLoggedIn) {
          sciAuth.clearAuthTokenInLocalStorage();
          mmAuth.clearAuthTokenInLocalStorage();
          mmAuth.clearRefreshTokenInLocalStorage();
        }
      },
    }
  );
  const queryClient = useQueryClient();
  const router = useRouter();
  const routerPush = useStableFunctionIdentity(router.push);

  const refreshTokensMutation = useMutation({
    mutationFn: async ({ refreshToken }: { refreshToken: string }) => {
      const refreshed = await mmApiClient.auth.getFreshAccessToken.mutate({
        refreshToken,
      });
      sciAuth.setAuthTokenInLocalStorage(refreshed.legacySciApiAccessToken);
      mmAuth.setAuthTokenInLocalStorage(refreshed.accessToken);
      return {
        user: refreshed.user,
        refreshToken,
        legacySciApiAccessToken: refreshed.legacySciApiAccessToken,
        accessToken: refreshed.accessToken,
      };
    },
  });

  const setAuthState = useCallback<SetAuthStateFn>(
    async (error, params) => {
      if (error || !params) {
        sciAuth.clearAuthTokenInLocalStorage();
        mmAuth.clearAuthTokenInLocalStorage();
        mmAuth.clearRefreshTokenInLocalStorage();
        routerPush('/error');
        return;
      }
      const { redirectUrl = '/', user, legacySciApiAccessToken, accessToken, refreshToken } = params;
      sciAuth.setAuthTokenInLocalStorage(legacySciApiAccessToken);
      mmAuth.setAuthTokenInLocalStorage(accessToken);
      if (refreshToken) {
        mmAuth.setRefreshTokenInLocalStorage(refreshToken);
      }
      refetch();
      trackFirebaseEvent({
        eventName: 'USER_SIGNED_IN',
        userId: user?.id,
        membershipTier: user?.membershipTier,
      });
      routerPush(redirectUrl);
    },
    [refetch, routerPush]
  );

  const loginWithSSOCode = useStableFunctionIdentity(loginWithSSOCodeMutation.mutateAsync);
  const finishAuth = useCallback<FinishAuthFn>(
    async (ssoCode, redirectUrl = '/') => {
      try {
        const { accessToken, legacySciApiAccessToken, user, refreshToken } = await loginWithSSOCode({ ssoCode });
        setAuthState(null, {
          redirectUrl,
          user,
          legacySciApiAccessToken,
          accessToken,
          refreshToken,
        });
      } catch (err) {
        setAuthState(err, null);
      }
    },
    [setAuthState, loginWithSSOCode]
  );

  const loginWithBasicAuthMutateAsync = useStableFunctionIdentity(loginWithBasicAuthMutation.mutateAsync);
  // TODO: refactor common login handling into shared function
  const loginWithBasicAuth = useCallback<LoginWithBasicAuthFn>(
    async ({ redirectUrl = '/', username, password }) => {
      try {
        const { accessToken, legacySciApiAccessToken, user, refreshToken } = await loginWithBasicAuthMutateAsync({
          username,
          password,
        });
        setAuthState(null, {
          accessToken,
          legacySciApiAccessToken,
          user,
          redirectUrl,
          refreshToken,
        });
      } catch (err) {
        setAuthState(err, null);
      }
    },
    [loginWithBasicAuthMutateAsync, setAuthState]
  );

  const logout = useCallback(async () => {
    sciAuth.clearAuthTokenInLocalStorage();
    mmAuth.clearAuthTokenInLocalStorage();
    mmAuth.clearRefreshTokenInLocalStorage();
    queryClient.clear();
    mmAuth.goToLogoutPage();
  }, [queryClient]);

  const isLoggingIn =
    isLoading ||
    refreshTokensMutation.isLoading ||
    data.isLoggingIn ||
    loginWithBasicAuthMutation.isLoading ||
    loginWithSSOCodeMutation.isLoading ||
    false;

  const refreshTokens = useStableFunctionIdentity(refreshTokensMutation.mutateAsync);

  const value = useMemo<AuthContextValue>(() => {
    return {
      initiateAuth: mmAuth.initiateAuth,
      isLoggedIn: isLoggingIn ? false : data.isLoggedIn,
      isLoggingIn,
      finishAuth,
      logout,
      user: data.authUser,
      loginWithBasicAuth,
      setAuthState,
      impersonatedByUserId: data.impersonatedByUserId,
      refreshTokens,
    };
  }, [data, finishAuth, logout, isLoggingIn, loginWithBasicAuth, setAuthState, refreshTokens]);

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}
