import { createContext, ReactNode, useCallback, useContext, useMemo, useRef } from 'react';
import { NextRouter, useRouter } from 'next/router';
import qs, { ParsedQs } from 'qs';
import { getHash, getQuery, removeQuery } from '../utils/queryString';

export {
  getBooleanParam,
  getNumericArrayParam,
  getNumericParam,
  getStringParam,
  getStringArrayParam,
  makeBooleanParam,
} from '../utils/queryString';

interface QueryStringContextValue {
  query: ParsedQs;
  setQueryParam: SetQueryParamFn;
  setQueryParams: (params?: object, clearOther?: boolean) => void;
  hash: string;
}
type SetQueryParamFn = (
  key: string,
  value: number | undefined | null | string | string[] | number[],
  clearOther?: boolean
) => void;

const QueryStringContext = createContext<QueryStringContextValue | undefined>(undefined);

export function QueryStringProvider({ children }: { children: ReactNode }) {
  const router = useRouter();
  // NOTE: prevent callback functions identity change on render
  const routerRef = useRef<NextRouter>(router);
  routerRef.current = router; // NOTE: always have reference for the latest value

  const query = useMemo(() => qs.parse(getQuery(router.asPath)), [router.asPath]);
  const hash = useMemo(() => getHash(router.asPath), [router.asPath]);
  // NOTE: handle concurrent (within the same render) query changes (multiple changes happen before asPath is updated)
  const queryRef = useRef<any>(query);
  queryRef.current = query; // NOTE: always have reference for the latest value

  const setQueryParam = useCallback<SetQueryParamFn>((key, value, clearOther = false) => {
    const { asPath, replace } = routerRef.current;
    queryRef.current = {
      ...(clearOther ? {} : queryRef.current),
      [key]: value,
    };
    const urlWithoutQSAndHash = removeQuery(asPath);
    const url = `${urlWithoutQSAndHash}${qs.stringify(queryRef.current, {
      addQueryPrefix: true,
      skipNulls: true,
      arrayFormat: 'repeat',
    })}`;
    replace(url, url, {
      shallow: true,
    });
  }, []);
  const setQueryParams = useCallback((params: object = {}, clearOther = false) => {
    const { asPath, replace } = routerRef.current;
    queryRef.current = {
      ...(clearOther ? {} : queryRef.current),
      ...params,
    };
    const urlWithoutQSAndHash = removeQuery(asPath);
    const url = `${urlWithoutQSAndHash}${qs.stringify(queryRef.current, {
      addQueryPrefix: true,
      skipNulls: true,
      arrayFormat: 'repeat',
    })}`;
    replace(url, url, {
      shallow: true,
    });
  }, []);

  const value = useMemo<QueryStringContextValue>(
    () => ({
      query,
      setQueryParam,
      setQueryParams,
      hash,
    }),
    [query, setQueryParam, setQueryParams, hash]
  );

  return <QueryStringContext.Provider value={value}>{children}</QueryStringContext.Provider>;
}

export default function useQueryString() {
  const value = useContext(QueryStringContext);
  if (!value) {
    throw new Error('useQueryString must be used within QueryStringProvider');
  }
  return value;
}
