import React from 'react';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import dynamic from 'next/dynamic';
import { withLDProvider } from 'launchdarkly-react-client-sdk';
import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { SciUiConfigProvider } from '@sportscardinvestor/sci-ui/components/sci-ui-config-provider';
import { useRouter } from 'next/router';
import { Page } from '../features/app/types';
import AuthContextProvider from '../features/auth/AuthContextProvider';
import queryClient from '../services/queryClient';
import ErrorBoundary from '../sci-ui-components/ErrorBoundary/ErrorBoundary';
import { QueryStringProvider } from '../hooks/useQueryString';
import publicConfig from '../publicConfig';
import { sciUiConfig } from '../sciUiConfig';

import '../sci-ui-components/styles/antThemeConfig';
import '../sci-ui-components/styles/baseline.scss';
import '../fonts.css';
import '../globals.css';
import { HelpCallToAction } from '../features/help/HelpCallToAction';
import { AnalyticsStateSync } from '@/features/analytics/useAnalyticsStateSync';

// NOTE: dynamic imports are used to allow efficient bundle splitting by webpack: memebers app bundle will not include admin-specific features and vice-vera
const AdminAppShell = dynamic(() => import('../features/app/shells/AdminApp/AdminApp'));
const MembersAppShell = dynamic(() => import('../features/app/shells/MembersApp/MembersApp'));
const MinimalAppShell = dynamic(() => import('../features/app/shells/MinimalApp/MinimalApp'));

type AppPropsWithLayout = AppProps & {
  Component: Page;
};

function App(appProps: AppPropsWithLayout) {
  const { Component } = appProps;
  const { pathname } = useRouter();

  return (
    <>
      <Head>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      <SciUiConfigProvider config={sciUiConfig}>
        <QueryClientProvider client={queryClient}>
          <AuthContextProvider>
            <AnalyticsStateSync />
            <QueryStringProvider>
              <HelpCallToAction />
              <ErrorBoundary dependencies={[pathname]}>
                {(Component.shell === 'members' || !Component.shell) && <MembersAppShell {...appProps} />}
                {Component.shell === 'admin' && <AdminAppShell {...appProps} />}
                {Component.shell === 'minimal' && <MinimalAppShell {...appProps} />}
              </ErrorBoundary>
            </QueryStringProvider>
          </AuthContextProvider>
          <ReactQueryDevtools initialIsOpen={false} />
        </QueryClientProvider>
      </SciUiConfigProvider>
    </>
  );
}

export default publicConfig.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID
  ? withLDProvider<AppPropsWithLayout & JSX.IntrinsicAttributes>({
      clientSideID: publicConfig.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
    })(App)
  : App;
