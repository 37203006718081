import { useEffect } from 'react';
import { useRouter } from 'next/router';
import useUser from '../user/useUser';
import { useAnalyticsStore } from './store';

export function useAnalyticsStateSync() {
  const { data: user = null } = useUser();
  const { pathname } = useRouter();

  useEffect(
    () =>
      useAnalyticsStore.setState({
        pathname,
      }),
    [pathname]
  );
  useEffect(() => {
    useAnalyticsStore.setState({
      user,
    });
  }, [user]);
}

export function AnalyticsStateSync() {
  useAnalyticsStateSync();
  return null;
}
