import React, { ReactNode } from 'react';
import * as Sentry from '@sentry/nextjs';
import { faCircleExclamation } from '@fortawesome/pro-light-svg-icons';
import InfoPageWrapper, { InfoPageButton } from '../InfoPageWrapper/InfoPageWrapper';

interface Props {
  dependencies?: any[];
  children: ReactNode;
}

interface State {
  hasError: boolean;
  errorInfo: null | any;
  error: any | null;
}

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    // Define a state variable to track whether is an error or not
    this.state = { hasError: false, errorInfo: null, error: null };
  }
  static getDerivedStateFromError(error: any) {
    // Update state so the next render will show the fallback UI

    return { hasError: true, errorInfo: error, error };
  }
  componentDidCatch(error: any, errorInfo: any) {
    this.setState({
      hasError: true,
      error: error,
      errorInfo: errorInfo,
    });
    // You can use your own error logging service here
    Sentry.captureException(error, { extra: errorInfo });
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const newDependencies = this.props.dependencies ?? [];
    if (prevProps.dependencies && prevProps.dependencies.some((dep, i) => newDependencies[i] !== dep)) {
      this.setState({
        hasError: false,
        error: null,
        errorInfo: null,
      });
    }
  }

  render() {
    // Check if the error is thrown
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <InfoPageWrapper goToDashboard={false} message={{ text: 'Something went wrong.', faIcon: faCircleExclamation }}>
          <InfoPageButton onClick={() => window.location.reload()}>Reload page</InfoPageButton>
        </InfoPageWrapper>
      );
    }

    // Return children components in case of no error

    return this.props.children;
  }
}

export function withErrorBoundary<TProps extends object>(Component: (props: TProps) => JSX.Element) {
  return function ComponentWithErrorBoundary(props: TProps) {
    return (
      <ErrorBoundary>
        <Component {...props} />
      </ErrorBoundary>
    );
  };
}

export default ErrorBoundary;
