import type { SciUiConfig } from '@sportscardinvestor/sci-ui/components/sci-ui-config-provider';
import Link from 'next/link';

export const sciUiConfig: SciUiConfig = {
  LinkComponent: Link,
  marketplaces: {
    ebay_jobs: {
      logoImageUrl: '/logos/marketplaces/ebay.png',
    },
    goldin_auctions: {
      logoImageUrl: '/logos/marketplaces/goldin_auctions.png',
    },
    heritage: {
      logoImageUrl: '/logos/marketplaces/heritage.png',
    },
    pwcc: {
      logoImageUrl: '/logos/marketplaces/pwcc.png',
    },
    pristine_auction: {
      logoImageUrl: '/logos/marketplaces/pristine_auction.png',
    },
    myslabs_jobs: {
      logoImageUrl: '/logos/marketplaces/myslabs.png',
    },
    raw: {
      logoImageUrl: '/logos/marketplaces/raw.png',
    },
    '90s_auctions': {
      logoImageUrl: '/logos/marketplaces/90s_auctions.png',
    },
    huggins_and_scott: {
      logoImageUrl: '/logos/marketplaces/huggins_and_scott.png',
    },
    lelands: {
      logoImageUrl: '/logos/marketplaces/lelands.png',
    },
    love_of_the_game: {
      logoImageUrl: '/logos/marketplaces/love_of_the_game.png',
    },
    cardshq: {
      logoImageUrl: '/logos/marketplaces/cardshq.png',
    },
  },
  placeholders: {
    noCardImageUrl: '../public/images/sports-card-no-image.jpg',
  },
};
