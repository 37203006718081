import { create } from 'zustand';
import { User } from '../auth/types';

interface AnalyticsState {
  user: User | null;
  pathname: string;
}

export const useAnalyticsStore = create<AnalyticsState>()(() => ({
  user: null,
  pathname: '/',
}));
