import { useAuthenticatedMMAPIQuery, mmApiClient } from '../../services/mmApiX/index';
import useAuth from '../auth/useAuth';

export type { User, MembershipTier } from '../auth/types';

export default function useUser() {
  const { user } = useAuth();
  const queryResult = useAuthenticatedMMAPIQuery(['private.user'], () => mmApiClient.private.user.query(), {
    staleTime: 1000 * 60 * 60 * 1, // 1 hour
    initialData: user
      ? {
          ...user,
          isExpired: false,
          id: user.userId,
        }
      : undefined,
  });

  return queryResult;
}
