import React, { forwardRef, ReactNode } from 'react';
import { FontAwesomeIcon, FontAwesomeIconProps } from '@fortawesome/react-fontawesome';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';
import NextLink from 'next/link';
import clsx from 'clsx';
import Button, { ButtonProps } from '../../sci-ui-components/buttons/Button/Button';
import { Route } from '../../features/app/constants';
import AppPadding from '../AppPadding/AppPadding';

import classes from './InfoPageWrapper.module.scss';

interface Message {
  variant?: 'default' | 'success' | 'error';
  text: string;
  faIcon?: IconDefinition;
  iconProps?: Omit<FontAwesomeIconProps, 'icon'>;
}

interface Props {
  message: Message;
  details?: Message[];
  goToDashboard?: boolean;
  children?: ReactNode;
}

export default function InfoPageWrapper({ message, details, goToDashboard = true, children }: Props) {
  return (
    <AppPadding className={classes.root}>
      {!!message.faIcon && (
        <FontAwesomeIcon
          size="10x"
          icon={message.faIcon}
          {...(message.iconProps ?? {})}
          className={clsx(classes.icon, {
            [classes.successIcon]: message.variant === 'success',
            [classes.errorIcon]: message.variant === 'error',
          })}
        />
      )}
      <h1 className={clsx(classes.labelText)}>{message.text}</h1>
      {!!details?.length && (
        <ul>
          {details.map((detail, index) => (
            <li key={index}>
              {!!detail.faIcon && (
                <FontAwesomeIcon
                  size="1x"
                  icon={detail.faIcon}
                  {...(detail.iconProps ?? {})}
                  className={clsx(classes.icon, classes.detailIcon, {
                    [classes.successIcon]: detail.variant === 'success',
                    [classes.errorIcon]: detail.variant === 'error',
                  })}
                />
              )}
              <span>{detail.text}</span>
            </li>
          ))}
        </ul>
      )}

      {goToDashboard && (
        <NextLink href={Route.Dashboard} legacyBehavior>
          <InfoPageButton href={Route.Dashboard} variant="success">
            Go to Dashboard
          </InfoPageButton>
        </NextLink>
      )}

      {children}
    </AppPadding>
  );
}

type InfoPageButtonProps = ButtonProps & { variant?: 'default' | 'success' };

export const InfoPageButton = forwardRef<HTMLElement, InfoPageButtonProps>(function InfoPageButton(
  { className, variant, ...otherProps }: InfoPageButtonProps,
  ref
) {
  return (
    <Button
      className={clsx(classes.goToDashboard, className, { [classes.successButton]: variant === 'success' })}
      ref={ref}
      {...otherProps}
    />
  );
});
