import type { Analytics } from 'firebase/analytics';
import { AnalyticsEvent } from '@sportscardinvestor/analytics-events';
import firebaseAppPromise from '../index';
import { User } from 'features/auth/types';

// NOTE: dynamic import to exclude from bundle and only fetch on clientside
const analyticsModule = import('firebase/analytics');
const analyticsPromise: Promise<Analytics | null> = (async () => {
  const [firebaseApp, { getAnalytics, isSupported }] = await Promise.all([firebaseAppPromise, analyticsModule]);
  return firebaseApp && (await isSupported()) ? getAnalytics(firebaseApp) : null;
})();

export async function trackFirebaseEvent({ eventName, ...otherParams }: AnalyticsEvent) {
  const [{ logEvent }, analytics] = await Promise.all([analyticsModule, analyticsPromise]);
  if (analytics) {
    logEvent(analytics, eventName, otherParams);
  } else {
    console.debug('Would track analytics event if it was initialized: ', {
      eventName,
      ...otherParams,
    });
  }
}

export async function identify(user: User | null) {
  const [{ setUserId, setUserProperties }, analytics] = await Promise.all([analyticsModule, analyticsPromise]);
  if (analytics && user) {
    setUserId(analytics, String(user.id));
    setUserProperties(analytics, {
      membershipTier: user.membershipTier,
      isAdmin: user.isAdmin,
    });
  }
}
