import { DetailedHTMLProps, ForwardedRef, forwardRef, HTMLAttributes, ReactNode } from 'react';
import clsx from 'clsx';

import classes from './AppPadding.module.scss';

export type Props = DetailedHTMLProps<HTMLAttributes<HTMLElement>, HTMLElement> & {
  children: ReactNode | ReactNode[];
  noBottom?: boolean;
  noTop?: boolean;
  noLeft?: boolean;
  noRight?: boolean;
};

export default forwardRef<HTMLAnchorElement | HTMLDivElement, Props>(function AppPadding(
  { className, noTop, noBottom, noLeft, noRight, ...other }: Props,
  ref
) {
  return (
    <div
      {...other}
      ref={ref as ForwardedRef<HTMLDivElement>}
      className={clsx(
        {
          [classes.top]: !noTop,
          [classes.bottom]: !noBottom,
          [classes.left]: !noLeft,
          [classes.right]: !noRight,
        },
        className
      )}
    />
  );
});
