import { ParsedQs } from 'qs';

export function getNumericParam<TResult extends number | null | undefined>(
  query: ParsedQs,
  key: string,
  defaultValue: TResult | number
): TResult | number {
  const value = query[key];
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  const valueToParse = Array.isArray(value) ? value[0] : value;
  const [parsed, isValid] = parseNumberString(valueToParse);
  return isValid ? parsed : defaultValue;
}

export function getNumericArrayParam<TResult extends number[] | null | undefined>(
  query: ParsedQs,
  key: string,
  defaultValue: TResult | number[]
): TResult | number[] {
  const value = query[key];
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  const valuesToParse = Array.isArray(value) ? value : [value];
  return valuesToParse.reduce<number[]>((acc, valueToParse) => {
    const [parsed, isValid] = parseNumberString(valueToParse);
    if (isValid) {
      acc.push(parsed);
    }
    return acc;
  }, []);
}

function parseNumberString(value: string | ParsedQs | undefined): [number, boolean] {
  const parsed = Number.parseInt(String(value), 10);
  const isValid = !isNaN(parsed) && parsed !== null;
  return [parsed, isValid];
}

export function getStringParam<TResult extends string | null | undefined>(
  query: ParsedQs,
  key: string,
  defaultValue: TResult,
  validate?: (value: TResult) => boolean
): TResult {
  const value = query[key];
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  const castedValue = Array.isArray(value) ? (String(value[0]) as TResult) : (String(value) as TResult);
  if (!validate || validate(castedValue)) {
    return castedValue;
  }
  return defaultValue;
}

export function getStringArrayParam<TItem extends string, TDefaultValue extends TItem[] | undefined = TItem[]>(
  query: ParsedQs,
  key: string,
  defaultValue: TDefaultValue,
  validate: (value: TItem) => boolean = (v) => !!v
): TItem[] | TDefaultValue {
  const value = query[key];
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  const strings = Array.isArray(value) ? value.map((v) => String(v) as TItem) : [String(value) as TItem];
  return strings.filter(validate);
}

export function getBooleanParam<TResult extends boolean | null | undefined>(
  query: ParsedQs,
  key: string,
  defaultValue: TResult | boolean
): TResult | boolean {
  const value = query[key];
  if (typeof value === 'undefined') {
    return defaultValue;
  }
  if (Array.isArray(value)) {
    return String(value[0]) === '1';
  }
  return String(value) === '1';
}

export function makeBooleanParam<TResult extends boolean | null | undefined>(value: TResult | boolean) {
  if (value === undefined) return undefined;
  return value ? '1' : '0';
}

export function removeQuery(url: string) {
  return url.replace(/\?.*$/, '');
}

export function getQuery(url: string) {
  return url.includes('?') ? url.replace(/^.*\?/, '').replace(/\#.*$/, '') : '';
}

export function getHash(url: string) {
  return url.includes('#') ? url.replace(/^.*\#/, '#') : '';
}

export function hasSomeProperties(obj: object) {
  return Object.entries(obj).some((kv) => !!kv[1] || kv[1] === 0);
}
