import { useCallback, useRef } from 'react';

/**
 * Returns a function that is never re-initialized, but when executed, always uses latest scope.
 * The purpose of this hook is to get a stable function identity, that does not change on each render.
 * Generally, when a function is used in dependencies array of useMemo, useEffect or useCallback hooks, each time the function identity changes (function is reinitialised, useEffect will trigger. That is often not desired behaviour.
 * For example:
 *   const trackAnalytics = (collectibleId: number) => {
 *    track({ ..otherPropsFromCurrentUserContext, collectibleId })
 *   }
 *   useEffect(() => trackAnalytics(collectibleId), [collectibleId, trackAnalytics])
 *   // this effect will run every time the component re-renders. We want it to run once.
 * Usually, it is enough to just wrap the function in useCallback, but that hook itself requires a dependencies array.
 * In case all we need is a function with a stable identity that when executed always takes the latest values for all of it's dependencies, useStableFunctionIdentity would be a solution.
 *   const trackAnalytics = useStableFunctionIdentity(the (collectibleId: number) => {
 *    track({ ..otherPropsFromCurrentUserContext, collectibleId })
 *   })
 *   useEffect(() => trackAnalytics(collectibleId), [collectibleId, trackAnalytics])
 *   // this effect will run only when `collectibleId` changes and on the initial render
 * @param fn
 * @returns fn
 */
export default function useStableFunctionIdentity<TFunc extends (...params: any[]) => any>(fn: TFunc) {
  const ref = useRef<TFunc>(fn);
  ref.current = fn;
  // @ts-ignore-next
  return useCallback<TFunc>((...params) => ref.current(...params), [ref]);
}
