const publicConfig = {
  NEXT_PUBLIC_APPLICATION_ENVIRONMENT: process.env.NEXT_PUBLIC_APPLICATION_ENVIRONMENT,
  NEXT_PUBLIC_CAMPAIGN_ID: process.env.NEXT_PUBLIC_CAMPAIGN_ID,
  NEXT_PUBLIC_GOOGLE_ANALYTICS_GTAG: process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS_GTAG,
  NEXT_PUBLIC_WP_HOST: process.env.NEXT_PUBLIC_WP_HOST,
  NEXT_PUBLIC_SCI_API_BASE_URL: process.env.NEXT_PUBLIC_SCI_API_BASE_URL,
  NEXT_PUBLIC_EBAY_ROTATION_ID: process.env.NEXT_PUBLIC_EBAY_ROTATION_ID,
  NEXT_PUBLIC_FIREBASE_API_KEY: process.env.NEXT_PUBLIC_FIREBASE_API_KEY,
  NEXT_PUBLIC_FIREBASE_PROJECT_ID: process.env.NEXT_PUBLIC_FIREBASE_PROJECT_ID,
  NEXT_PUBLIC_FIREBASE_APP_ID: process.env.NEXT_PUBLIC_FIREBASE_APP_ID,
  NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID: process.env.NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID,
  NEXT_PUBLIC_SCI_WORDPRESS_BASE_URL: process.env.NEXT_PUBLIC_SCI_WORDPRESS_BASE_URL,
  NEXT_PUBLIC_YOUTUBE_SCI_CHANNEL_IDS: process.env.NEXT_PUBLIC_YOUTUBE_SCI_CHANNEL_IDS?.split(','),
  NEXT_PUBLIC_MARKET_MOVERS_API_BASE_URL: process.env.NEXT_PUBLIC_MARKET_MOVERS_API_BASE_URL,
  NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID: process.env.NEXT_PUBLIC_LAUNCH_DARKLY_CLIENT_ID,
  NEXT_PUBLIC_PWCC_PARTNER_ID: process.env.NEXT_PUBLIC_PWCC_PARTNER_ID,
  NEXT_PUBLIC_PWCC_AD_PROGRAM_ID: process.env.NEXT_PUBLIC_PWCC_AD_PROGRAM_ID,
  NEXT_PUBLIC_PWCC_IMPACT_TRACKING_ID: process.env.NEXT_PUBLIC_PWCC_IMPACT_TRACKING_ID,
  NEXT_PUBLIC_EBAY_DIRECT_AUCTION_CAMPAIGN_ID: process.env.NEXT_PUBLIC_EBAY_DIRECT_AUCTION_CAMPAIGN_ID,
  NEXT_PUBLIC_EBAY_DIRECT_FIXED_PRICE_CAMPAIGN_ID: process.env.NEXT_PUBLIC_EBAY_DIRECT_FIXED_PRICE_CAMPAIGN_ID,
  NEXT_PUBLIC_EBAY_SEARCH_CAMPAIGN_ID: process.env.NEXT_PUBLIC_EBAY_SEARCH_CAMPAIGN_ID,
};

export type PublicConfig = typeof publicConfig;

export function getRequiredConfigVar<TKey extends keyof PublicConfig>(
  key: TKey
): Exclude<PublicConfig[TKey], undefined | null> {
  const value = publicConfig[key];
  if (typeof value === 'undefined' || value === null) {
    throw new Error(`Required config variable "${key}" is missing`);
  }
  return value as Exclude<PublicConfig[TKey], undefined | null>;
}

export default publicConfig;
