import * as localStorage from '../../utils/localStorage';
import { parseJwt } from '../../utils/jwt';

const { StorageKey } = localStorage;

export function getAuthTokenFromLocalStorage() {
  const storedValue = localStorage.getItem(StorageKey.SCIAPIAccessToken)?.replace('Bearer ', '');
  if (!storedValue) {
    return null;
  }
  const authToken = cleanUpToken(storedValue);
  const tokenPayload = parseJwt(authToken);
  if (!tokenPayload) {
    return null;
  }
  if (new Date(tokenPayload.exp * 1000) <= new Date()) {
    return null;
  }
  return authToken;
}

export function setAuthTokenInLocalStorage(authToken: string) {
  localStorage.setItem(StorageKey.SCIAPIAccessToken, authToken);
}

export function clearAuthTokenInLocalStorage() {
  localStorage.removeItem(StorageKey.SCIAPIAccessToken);
}

function cleanUpToken(value: string) {
  let result = value.startsWith('"') ? value.slice(1) : value;
  result = result.endsWith('"') ? result.slice(0, -1) : result;
  return result.replace('Bearer ', '');
}
