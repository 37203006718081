import { QueryClient } from 'react-query';
import { RouterOutput } from '@sportscardinvestor/market-movers-api-client';
import publicConfig from '../../publicConfig';
import * as localStorage from '../../utils/localStorage';
import { parseJwt } from '../../utils/jwt';
import { ApiError } from '../../utils/api';
import { TRPCError, authQueryKey } from './types';

const { StorageKey } = localStorage;

const AUTH_ENDPOINTS = {
  LOGOUT: `${publicConfig.NEXT_PUBLIC_WP_HOST}/wp-login.php?loggedout=true&redirect_to=sci_v2&wp_lang=en_US`,
  SSO_VERIFY: `${publicConfig.NEXT_PUBLIC_WP_HOST}/tft_sso/wp_verify?rt=login&redirect_app=sci_v2`,
};

export type AuthenticatedUser = RouterOutput['auth']['loginWithSSOCode']['user'];

export function initiateAuth() {
  window.location.assign(AUTH_ENDPOINTS.SSO_VERIFY);
}

export function goToLogoutPage() {
  window.location.assign(AUTH_ENDPOINTS.LOGOUT);
}

export function getAuthTokenFromLocalStorage() {
  const authToken = localStorage.getItem(StorageKey.MMAPIAccessToken);
  if (!authToken) {
    return null;
  }
  const tokenPayload = parseJwt(authToken);
  if (!tokenPayload) {
    return null;
  }
  if (new Date(tokenPayload.exp * 1000) <= new Date()) {
    return null;
  }
  return authToken;
}

export function setAuthTokenInLocalStorage(authToken: string) {
  localStorage.setItem(StorageKey.MMAPIAccessToken, authToken);
}

export function clearAuthTokenInLocalStorage() {
  localStorage.removeItem(StorageKey.MMAPIAccessToken);
}

export function getRefreshTokenFromLocalStorage() {
  const refreshToken = localStorage.getItem(StorageKey.MMAPIRefreshToken);
  if (!refreshToken) {
    return null;
  }
  const tokenPayload = parseJwt(refreshToken);
  if (!tokenPayload) {
    return null;
  }
  if (new Date(tokenPayload.exp * 1000) <= new Date()) {
    return null;
  }
  return refreshToken;
}

export function setRefreshTokenInLocalStorage(refreshToken: string) {
  localStorage.setItem(StorageKey.MMAPIRefreshToken, refreshToken);
}

export function clearRefreshTokenInLocalStorage() {
  localStorage.removeItem(StorageKey.MMAPIRefreshToken);
}

export function checkIfAuthError(error: unknown) {
  return (error as TRPCError).data?.code === 'UNAUTHORIZED' || (error as ApiError).status === 401;
}

export function withAuth<TFn extends (...params: any[]) => Promise<any>>(fn: TFn, queryClient: QueryClient): TFn {
  const wrapped = (async (...params) => {
    const fnWithAuth = () => {
      const token = getAuthTokenFromLocalStorage();
      if (!token) {
        throw new ApiError('Unauthenticated', 401, {
          message: 'No auth token',
        });
      }
      return fn(...params);
    };
    try {
      return await fnWithAuth();
    } catch (err) {
      if (checkIfAuthError(err)) {
        // NOTE: refetch function will be only called once when it is called by many parallel api calls
        await queryClient.refetchQueries(...authQueryKey);
        return await fnWithAuth();
      }
      throw err;
    }
  }) as TFn; // NOTE: it is hard to explain to TS that this does match the Fn type no matter what that type is
  return wrapped;
}
