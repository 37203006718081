import { useContext } from 'react';
import AuthContext from './AuthContext';

export default function useAuth() {
  const value = useContext(AuthContext);
  if (!value) {
    throw new Error('useAuth must be used in AuthContextProvider');
  }
  return value;
}
