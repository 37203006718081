import { TRPCClientErrorLike } from '@trpc/client';
import type { AppRouter } from '@sportscardinvestor/market-movers-api-client';

export type { Collectible } from '@sportscardinvestor/schemas';
export type {
  RouterInput as MmApiInput,
  RouterOutput as MmApiOutput,
} from '@sportscardinvestor/market-movers-api-client';

export type TRPCError = TRPCClientErrorLike<AppRouter>;

export const authQueryKey = ['auth'] as const;
