import type { FirebaseOptions } from 'firebase/app';
import envConfig, { getRequiredConfigVar } from '../../publicConfig';

async function initFirebase() {
  if (typeof window === undefined) {
    return null;
  }
  if (envConfig.NEXT_PUBLIC_APPLICATION_ENVIRONMENT !== 'production') {
    return null;
  }
  const config = getConfig();
  if (!config) {
    return null;
  }
  const { initializeApp } = await import('firebase/app');
  const app = initializeApp(config);
  console.debug('Firebase has been initialized');
  return app;
}

function getConfig(): FirebaseOptions | null {
  try {
    return {
      apiKey: getRequiredConfigVar('NEXT_PUBLIC_FIREBASE_API_KEY'),
      projectId: getRequiredConfigVar('NEXT_PUBLIC_FIREBASE_PROJECT_ID'),
      appId: getRequiredConfigVar('NEXT_PUBLIC_FIREBASE_APP_ID'),
      measurementId: getRequiredConfigVar('NEXT_PUBLIC_FIREBASE_MEASUREMENT_ID'),
    };
  } catch (err) {
    console.error(err);
    return null;
  }
}

export default initFirebase();
